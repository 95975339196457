import { useEffect, useState } from "react";

import { Form } from "../../components/form/Form";
import {
  InputDateTime,
  InputEmail,
  InputText,
} from "../../components/form/Input";
import { useForm } from "react-hook-form";
import { useMutate } from "../../hooks/useMutations";
import { APP_API_URL } from "../../config/api";
import {
  CancelButton,
  SubmitButton,
} from "../../components/button/CustomButton";
import { toast } from "react-toastify";
import { HTTPStatusResponse, swal } from "../../config/global";
import LoaderBarWrapper from "../../components/loader/LoaderBarWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueries } from "../../hooks/useQueries";
import OnModel from "../model/OneModel";
import { Select2 } from "../../components/form/Select";

export default function CampaignCreatePage() {
  /**
   * Hooks
   */
  const navigate = useNavigate();
  const { state } = useLocation();
  const [selectedModel, setSelectedModel] = useState<any>(null);

  /**
   * Form
   */
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const watchModel = watch("model_id");

  /**
   * Query
   */
  const { data: models, isSuccess } = useQueries(
    APP_API_URL + `models?paginate=0`,
    ["models-list"]
  );

  const { data: groups } = useQueries(APP_API_URL + `groups?paginate=0`, [
    "groups-list",
  ]);

  /**
   * Mutation
   */
  const { mutateAsync, isLoading } = useMutate(
    APP_API_URL + `campaign-store`,
    "post"
  );

  /**
   * Function
   */
  const handleSubmitForm = (values: any) => {
    swal
      .fire({
        text: "Confirmer l'envoi de la campagne!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        allowOutsideClick: false,
        width: 400,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
            const currentDateTimeFromInput = values.schedule_time !== "" ? values.schedule_time : null;
            const currentDateTimeWithSpace = currentDateTimeFromInput ? currentDateTimeFromInput.replace("T", " ").toLocaleString('en-GB'): null;
            console.log("Data to be sent: ", {
                ...values,
                model_id: values.model_id.id,
                group_ids: values.group_ids.id.toString(),
                schedule_time:currentDateTimeWithSpace,
            })
          mutateAsync({
            ...values,
            model_id: values.model_id.id,
            group_ids: values.group_ids.id.toString(),
            /*group_ids: values.group_ids.map((group: any) => group.id),*/
            schedule_time: currentDateTimeWithSpace,
          }).then((response: any) => {
            if (response?.data?.status === HTTPStatusResponse.OK) {
              toast.success("La campagne a été envoyée");
              navigate("/campaigns");
            }
          }).catch((err) => {
            console.log("Something went wrong: ", err)
            
          });
        }
      });
  };

  /**
   * Effect
   */
  useEffect(() => {
    if (models) {
      const newModel = models?.data.data.find(
        (model: any) => model.id === parseInt(`${state?.model}`)
      );
      setSelectedModel(newModel);
      setValue("model_id", newModel);
    }
  }, [isSuccess]);

  useEffect(() => {
    setSelectedModel(watchModel);
  }, [watchModel]);

  /**
   * UI
   */
  return (
    <div className="relative">
      <LoaderBarWrapper loading={isLoading}>
        <Form
          className="p-4"
          loading={isLoading}
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-2/3 h-screen">
              {selectedModel ? (
                <OnModel
                  name={selectedModel?.name}
                  content={selectedModel?.content_html}
                  height={"100%"}
                />
              ) : (
                <div className="h-full bg-gray-200 animate-pulse w-full flex items-center justify-center">
                  <h1 className="text-1xl">
                    Selectionner un modèle pour avoir un aperçu
                  </h1>
                </div>
              )}
            </div>
            <div className="w-full md:w-1/3 bg-white p-4 rounded">
              <h1 className="text-2xl text-center underline font-bold mb-4">
                Création d'une campagne
              </h1>
              <div className={"grid grid-cols-1 md:grid-cols-2 gap-4 mb-4"}>
                <div className="md:col-span-2">
                  <Select2
                    placeholder="Selectionner le model"
                    control={control}
                    name={"model_id"}
                    error={errors?.model_id}
                    register={register}
                    textKeyItem={"name"}
                    valueKeyItem={"id"}
                    items={models?.data.data}
                    defaultValue={selectedModel}
                    label="Model *"
                  />
                </div>
                <div className="md:col-span-2">
                  <Select2
                    placeholder="Selectionner le groupe"
                    control={control}
                    name={"group_ids"}
                    error={errors?.group_ids}
                    register={register}
                    textKeyItem={"name"}
                    valueKeyItem={"id"}
                    items={groups?.data.data}
                    label="Group *"
                  />
                </div>
                <div>
                  <InputText
                    placeholder={"Entrer le nom de la campagne"}
                    label={"Nom *"}
                    register={register}
                    error={errors?.name}
                    name={"name"}
                  />
                </div>
                <div>
                  <InputText
                    placeholder={"Entrer l'objet de la campagne"}
                    label={"Objet *"}
                    register={register}
                    error={errors?.subject}
                    name={"subject"}
                  />
                </div>
                <div>
                  <InputText
                    placeholder={"Entrer le nom de de l'expéditeur"}
                    label={"Nom de l'expéditeur"}
                    register={register}
                    error={errors?.from_name}
                    name={"from_name"}
                    required={false}
                  />
                </div>
                <div>
                  <InputEmail
                    placeholder={"Entrer l'émail de l'expéditeur"}
                    label={"Email de l'expéditeur *"}
                    register={register}
                    error={errors?.from_email}
                    name={"from_email"}
                  />
                </div>
                <div>
                  <InputDateTime
                    className="w-full"
                    label={"Programmée la campagne"}
                    register={register}
                    error={errors?.schedule_time}
                    name={"schedule_time"}
                  />
                </div>
              </div>
              <div className="flex justify-between mt-20">
                <CancelButton onClick={( ) => navigate("/campaign")}>
                  Annuler
                </CancelButton>
                <SubmitButton value={"Démarrer"} />
              </div>
            </div>
          </div>
        </Form>
      </LoaderBarWrapper>
    </div>
  );
}
