import { PATTERN_EMAIL, PATTERN_PHONE } from "../../utils/pattern";
import { useEffect, useMemo, useState } from "react";
import SimpleButton from "../button/SimpleButton";
import Datepicker from "react-tailwindcss-datepicker";
import { InputPropsInterface, LabelPropsInterface } from "./typings";
import { Switch } from "@headlessui/react";
import { Controller } from "react-hook-form";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import InputSkeleton from "../skeleton/InputSkeleton";

export function Label({ name, text, className }: LabelPropsInterface) {
  return text ? (
    <label htmlFor={name} className={className}>
      {text}
    </label>
  ) : (
    <></>
  );
}

export function Input({
  name,
  register,
  options = { required: true },
  placeholder = "",
  error = "",
  type = "text",
  defaultValue = "",
  className = "form-control",
  showSkeleton = false,
  step
}: InputPropsInterface) {
  return (
    <>
      {showSkeleton ? (
        <InputSkeleton />
      ) : (
        <input
          type={type}
          step={step && "1"}
          id={name}
          className={`${className} ${error && "is-invalid"}`}
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...register(name, options)}
        />
      )}
    </>
  );
}

export function InputText({
  name,
  label = "",
  register,
  placeholder = "",
  error = "",
  defaultValue = "",
  className = "",
  options = { required: true, message: "Erreur" },
  showSkeleton = false,
}: InputPropsInterface) {
  return (
    <>
      <Label name={name} text={label} />
      <Input
        register={register}
        type={"text"}
        name={name}
        placeholder={placeholder}
        options={options}
        className={`form-control ${className}}`}
        error={error}
        defaultValue={defaultValue}
        showSkeleton={showSkeleton}
      />
    </>
  );
}

export function InputEmail({
  name,
  label = "",
  register,
  labelClassName = "",
  placeholder = "",
  error = "",
  defaultValue = "",
  required = true,
  options = {
    pattern: { value: PATTERN_EMAIL, message: "Erreur" },
  },
  showSkeleton = false,
}: InputPropsInterface) {
  return (
    <>
      <Label name={name} text={label} className={labelClassName} />
      <Input
        register={register}
        type={"email"}
        name={name}
        placeholder={placeholder}
        options={{ ...options, required: required }}
        defaultValue={defaultValue}
        error={error}
        showSkeleton={showSkeleton}
      />
    </>
  );
}

export function InputPhone({
  name,
  label = "",
  register,
  labelClassName = "",
  placeholder = "",
  error = "",
  defaultValue = "",
  required = true,
  options = {
    pattern: { value: PATTERN_PHONE, message: "Erreur" },
  },
  showSkeleton = false,
}: InputPropsInterface) {
  return (
    <>
      <Label name={name} text={label} className={labelClassName} />
      <Input
        register={register}
        type={"text"}
        name={name}
        placeholder={placeholder}
        options={{ ...options, required: required }}
        defaultValue={defaultValue}
        error={error}
        showSkeleton={showSkeleton}
      />
    </>
  );
}

export function InputPassword({
  name,
  label = "",
  register,
  labelClassName = "",
  placeholder = "",
  error = "",
  required = true,
}: InputPropsInterface) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Label name={name} text={label} className={labelClassName} />
      <div className="input-group auth-pass-inputgroup">
        <Input
          register={register}
          type={open ? "text" : "password"}
          name={name}
          placeholder={placeholder}
          options={{ required: required }}
          error={error}
        />
        <SimpleButton
          className="btn btn-light"
          onClick={() => setOpen(!open)}
          type="button"
        >
          {open ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
        </SimpleButton>
      </div>
    </>
  );
}

export function InputCheckbox({
  name,
  register,
  label = "",
  className = "",
  labelClassName = "form-check-label",
  options = {},
  required = true,
}: InputPropsInterface) {
  return (
    <>
      <div className={`form-check ${className}`}>
        <Input
          register={register}
          type={"checkbox"}
          name={name}
          className={"form-check-input"}
          options={{ ...options, required: required }}
        />
        <Label className={labelClassName} name={name} text={label} />
      </div>
    </>
  );
}

export function InputNumber({
  name,
  label = "",
  register,
  placeholder = "",
  error = "",
  defaultValue = "",
  required = true,
  options = { message: "Erreur" },
  showSkeleton = false,
}: InputPropsInterface) {
  return (
    <>
      <Label name={name} text={label} />
      <Input
        register={register}
        type={"number"}
        name={name}
        placeholder={placeholder}
        options={{ ...options, required: required }}
        defaultValue={defaultValue}
        error={error}
        showSkeleton={showSkeleton}
      />
    </>
  );
}

export function InputColor({
  name,
  label = "",
  register,
  placeholder = "",
  error = "",
  options = { message: "Erreur" },
  required = true,
  showSkeleton = false,
}: InputPropsInterface) {
  return (
    <>
      <Label name={name} text={label} />
      <Input
        register={register}
        type={"color"}
        name={name}
        placeholder={placeholder}
        options={{ ...options, required: required }}
        error={error}
        showSkeleton={showSkeleton}
      />
    </>
  );
}

export function InputDate({
  name,
  label = "",
  register,
  placeholder = "",
  error = "",
  required = true,
  options = { message: "Erreur" },
  showSkeleton = false,
  defaultValue = "",
}: InputPropsInterface) {
  return (
    <>
      <Label name={name} text={label} />
      <Input
        register={register}
        type={"date"}
        name={name}
        placeholder={placeholder}
        options={{ ...options, required: required }}
        error={error}
        showSkeleton={showSkeleton}
        defaultValue={defaultValue}
      />
    </>
  );
}

export function InputTime({
  name,
  label = "",
  register,
  placeholder = "",
  error = "",
  required = true,
  options = { message: "Erreur" },
}: InputPropsInterface) {
  return (
    <>
      <Label name={name} text={label} />
      <Input
        register={register}
        type={"time"}
        name={name}
        placeholder={placeholder}
        options={{ ...options, required: required }}
        error={error}
      />
    </>
  );
}

export function InputFile({
  name,
  register,
  label = "",
  placeholder = "",
  error = "",
  required = true,
  options = { message: "Erreur" },
  showSkeleton = false,
}: InputPropsInterface) {
  return (
    <>
      <Label name={name} text={label} />
      <Input
        register={register}
        type={"file"}
        name={name}
        placeholder={placeholder}
        options={{ ...options, required: required }}
        error={error}
        showSkeleton={showSkeleton}
      />
    </>
  );
}

export function InputDatePicker({
  name,
  label = "",
  options = { required: true, message: "Erreur" },
}: InputPropsInterface) {
  return (
    <>
      <Label name={name} text={label} />
      <Datepicker
        i18n={"fr"}
        asSingle={true}
        onChange={() => {}}
        useRange={false}
        toggleIcon={() => <></>}
        minDate={new Date()}
        inputClassName={"form-control"}
        /* configs={{
                shortcuts: {
                    today: "Aujourd'hui",
                    yesterday: "Hier",
                    past: period => `Les ${period}  derniers jours`,
                    currentMonth: "Ce mois-ci",
                    pastMonth: "Le mois dernier"
                }
            }} */
        value={{ startDate: null, endDate: null }}
      />
    </>
  );
}

export function InputUrl({
  name,
  label = "",
  register,
  placeholder = "",
  error = "",
  required = true,
  options = {},
}: InputPropsInterface) {
  return (
    <>
      <Label name={name} text={label} />
      <Input
        register={register}
        type={"url"}
        name={name}
        placeholder={placeholder}
        options={{ ...options, required: required }}
        error={error}
      />
    </>
  );
}

export function InputDateTime({
  name,
  label = "",
  register,
  placeholder = "",
  error = "",
  required = false,
  options = {},
}: InputPropsInterface) {
  return (
    <>
      <Label name={name} text={label} />
      <Input
        register={register}
        type={"datetime-local"}
        step={true}
        name={name}
        placeholder={placeholder}
        options={{ ...options, required: required }}
        error={error}
      />
    </>
  );
}

export function InputRadio({
  name,
  label = "",
  defaultValue = false,
  control,
  options = {},
  required = true,
}: InputPropsInterface) {
  return (
    <>
      <Label name={name} text={label} />
      <Controller
        defaultValue={defaultValue}
        control={control}
        rules={{ ...options, required: required }}
        render={({ field }) => {
          return (
            <Switch
              defaultChecked={defaultValue}
              checked={field.value ? true : false}
              {...field}
            >
              {({ checked }) => (
                <span
                  className={`${
                    checked ? "bg-green-400" : "bg-gray-300"
                  } relative inline-flex h-[24px] w-[48px] items-center rounded-full`}
                >
                  <span
                    className={`${
                      checked ? "translate-x-6" : "translate-x-2"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </span>
              )}
            </Switch>
          );
        }}
        name={name}
      />
    </>
  );
}

export function TextArea({
  name,
  label = "",
  register,
  placeholder = "",
  className = "",
  error = "",
  defaultValue = "",
  options = {},
  required = true,
}: InputPropsInterface) {
  return (
    <>
      <Label name={name} text={label} />
      <textarea
        {...register(name, { ...options, required: required })}
        id={name}
        className={`form-control ${className} ${error && "is-invalid"}`}
        placeholder={placeholder}
        defaultValue={defaultValue}
      ></textarea>
    </>
  );
}

export function InputCode({
  name,
  numberOfElement,
  setValue,
}: {
  name: string;
  numberOfElement: number;
  setValue: any;
}) {
  const [values, setValues] = useState(Array<string>(numberOfElement));

  const handleChangleInput = (e: any, i: number) => {
    if (e.target.value.length <= 1) {
      let newInputValue = values.slice();
      newInputValue[i] = e.target.value;
      setValues(newInputValue);
    }
  };

  /**
   * Component
   */
  const BuildInputCode = () => {
    const elements: JSX.Element[] = [];
    let simpleElement: JSX.Element;
    for (let i: number = 0; i < numberOfElement; i++) {
      simpleElement = (
        <input
          key={`input-code-${i}`}
          onChange={(e) => handleChangleInput(e, i)}
          maxLength={1}
          value={values[i]}
          className={`form-control form-input-code ${
            values[i] === "" ? "is-invalid" : ""
          }`}
          required
        />
      );
      elements.push(simpleElement);
    }
    return <>{elements}</>;
  };

  /**
   * Effect
   */
  useEffect(() => {
    setValue(name, values.toString().replaceAll(",", ""));
  }, [values, name, setValue]);

  /**
   * UI
   */
  return useMemo(() => <BuildInputCode />, [values]);
}
